import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "./MeetingFooter.css";
import { userIdForDB, userName, getRoomId } from "../../server/pyDbSpeaker";
import { connectionToPythonServerIsGiven, pythonDatachannel } from "../../server/peerConnection";

const MeetingFooter = (props) => {
  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });
  getState = streamState;

  const micClick = () => {
    setStreamState((currentState) => {
      if(connectionToPythonServerIsGiven){
        pythonDatachannel.send(JSON.stringify({...currentState, mic: !currentState.mic}))
      }
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      if(connectionToPythonServerIsGiven){
        pythonDatachannel.send(JSON.stringify({...currentState, video: !currentState.video}))
      }
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };

  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };

  const onDownloadClick = () => {
    var roomID= getRoomId() //pyDB.roomID
    var clientID = userIdForDB
    var clientName = userName
    var urlToProtocol= window.location.protocol + '//' + window.location.hostname + ':8060/protocol?roomId='+roomID+'&userId='+clientID+'&userName='+clientName
    window.open(urlToProtocol, "_blank", "noreferrer");
    //console.log("THIS LINK: ", urlToProtocol);
    //https://0.0.0.0:8060/protocol?roomId=Room-UUID&userId=User-UUID&userName=Paul
  }
  
  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="meeting-footer">
      <div
        className={"meeting-icons " + (!streamState.mic ? "active" : "")}
        data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
        onClick={micClick}
      >
        <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        />
      </div>
      <div
        className={"meeting-icons " + (!streamState.video ? "active" : "")}
        data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
        disabled={streamStateScreen}
      >
        <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} />
      </div>
      
      <div
        className={"meeting-icons " + (streamStateScreen ? "active" : "")}
        data-tip="Share Screen"
        onClick={onScreenClick}
        disabled={(getMobileOS()==="Android" || getMobileOS()==="iOS")}//? true : /*streamState.screen*/ streamStateScreen}
      >
        <FontAwesomeIcon icon={faDesktop} />

        
      </div>
      

      <div
        className={"meeting-icons downloadBg downloadIcon hidden"}
        data-tip="download"
        data-toggle="tooltip" 
        data-placement="top" 
        title="Download transcript"
        onClick={onDownloadClick}
        >
        <FontAwesomeIcon icon={faDownload} />

        
      </div>
      
    </div>
  );
};

let streamStateScreen = false;
export let getState = {mic: true,video: false,screen: false};
export const screenSharedUpdated = (screenSharedState) =>{
  if(connectionToPythonServerIsGiven){
    pythonDatachannel.send(JSON.stringify({...getState, screen: screenSharedState}))
  }
  streamStateScreen = screenSharedState;
}

const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
    return "iOS"
  }
  return "Other"
}

export default MeetingFooter;
