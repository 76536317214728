import MainScreen from "./components/MainScreen/MainScreen.component";
import "./App.css";
import { useEffect } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
  getUserStream
} from "./store/actioncreator";
import { connect } from "react-redux";
import { connectToPythonServer } from "./server/peerConnection";
import {databaseRootReference, db, userName, openNow} from "./server/pyDbSpeaker";
import disconnectSound from "./disconnect_sound.mp3";
import connectSound from "./connect_sound.mp3";

var my_stream;
function App(props) {
  
  useEffect(() => {
    async function getUserStreamAsync(){
      try{
      const stream = await getUserStream();
      //console.log("The Stream: ",stream);
      stream.getVideoTracks()[0].enabled = false;
      props.setMainStream(stream);
      my_stream = stream
      }catch (error) {
        console.error(error);
        //alert("Video- oder Audiozugriff wurde abgelehnt. Bitte stimmen Sie diesem zu und laden Sie die Webseite erneut.");
      }
    }
    getUserStreamAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const isStreamSet = !!props.stream;
  
  useEffect(() => {
    if(isStreamSet){
      const participantRef = databaseRootReference.child("participants");
      db.onConnected(() => {
        connectToPythonServer(my_stream);//props.mainStream);//Thomas
        const defaultPreference = {
          audio: true,
          video: false,
          screen: false,
        };
        const userStatusRef = participantRef.pushMult({
          userName,
          preferences: defaultPreference,
        });
        props.setUser({
          [userStatusRef.key]: { name: userName, ...defaultPreference },
        });
        userStatusRef.onDisconnect().delete();
      });
      openNow();
    }

  }, [isStreamSet]);// eslint-disable-line react-hooks/exhaustive-deps

  const participantRef = databaseRootReference.child("participants");
  const isUserSet = !!props.user;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      var myParticipantList = [];
      var myFirstConnection = false;
      participantRef.onMessage(response =>{
        // On Add
        if(response.performed === "push" && !(myParticipantList.includes(response.key))){
          const preferenceUpdateEvent = participantRef.child(response.key).child("preferences");

          // On Update
          preferenceUpdateEvent.onMessage(thisResponse => {
            if(thisResponse.performed === "update"){
              props.updateParticipant({
                [response.key]: {
                  ...thisResponse.value,
                },
              });
            }
          });

          const { userName: name, preferences = {} } = response.value;
          if(myFirstConnection){
            const audio_connect = new Audio(connectSound);
            audio_connect.loop = false;
            audio_connect.play();
          }else{
            setTimeout(()=>{myFirstConnection = true;},100);
          }
          props.addParticipant({
            [response.key]: {
              name,
              ...preferences,
            },
          });
          myParticipantList.push(response.key);
        }

        // On Delete
        if(response.performed === "delete"){
          props.removeParticipant(response.key);
          myParticipantList.pop(response.key);
          
          const audio_disconnect = new Audio(disconnectSound);
          audio_disconnect.loop = false;
          audio_disconnect.play();
        }
      });
    }
  }, [isStreamSet, isUserSet]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="App">
      <MainScreen />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
