import { setUserInfoJson_filled } from "../store/actioncreator";
import {pyDB, dbRef, generateUUID} from "./pyDB";
import Cookies from 'js-cookie';

//Set WebSocket URL and get Room ID
const wsURL = 'wss://' + window.location.hostname + ':8060/room/'; // PyDB Server Address
const urlparams = new URLSearchParams(window.location.search);
var roomId = urlparams.get("id"); //Room ID Parameter to Connect to
if(!roomId){roomId= "0";}
const connectionURL = wsURL+roomId;

function checkAndSetCookie(key, value){
  if(Cookies.get(key)){
    return true;
  } else {
    Cookies.set( key, value, { expires: 60 });
    return false;
  }
}
  

//Init pyDB Connection
export const db = new pyDB(connectionURL);
export function getRoomId(){
  return db.roomId;
}

//Get Username + ID and save them
function getName(){
    let name = prompt("Bitte geben Sie Ihren Namen ein:");
    if(name==null || name.length<1){
      name= "Unbekannt";
    }
    return name
  }

var alternativeusername = null;
if(Cookies.get("userName")){
  alternativeusername = Cookies.get("userName");
} else if(urlparams.get("name")){
  alternativeusername = urlparams.get("name");
  Cookies.set("userName", alternativeusername, { expires: 60 });
}
export const userName = alternativeusername ?alternativeusername: getName();  //urlparams.get("name") ?urlparams.get("name") :getName();
if(userName !== "Unbekannt"){
  checkAndSetCookie("userName", userName);
}
var alternativeID = Cookies.get("userID") ?Cookies.get("userID") :checkAndSetCookie("userID", generateUUID())
export const userIdForDB = urlparams.get("userId") ?urlparams.get("userId") : alternativeID; //"ID_"+userName;

//Open Database Connection and Reset URL with RoomID
export async function openNow(){
    await setUserInfoJson_filled();
    await db.open(()=>{
    if(roomId === "0" ){
        window.history.replaceState(null, "Meet", "?id=" + db.roomId);
    } else {
      db.roomId = roomId
      window.history.replaceState(null, "Meet", "?id=" + roomId);
    }
});
}



//Make a Database Reference for Root Path
export const databaseRootReference = new dbRef(db, []);