import {databaseRootReference, db, userName, userIdForDB} from "./pyDbSpeaker";
import { store } from "../index";
import { iceServersForPyServer } from "../store/reducer";
import { getSilentStreamId, getUserInfo, getUserInfoJson_filled } from "../store/actioncreator";
import { getState } from "../components/MeetingFooter/MeetingFooter.component";
//import { makeVideoImageSnap } from "../expression/snapshotImage";

const participantRef = databaseRootReference.child("participants");

export const updatePreference = (userId, preference) => {
  const currentParticipantRef = participantRef.child(userId).child("preferences");
  setTimeout(() => {
    currentParticipantRef.update(preference);
  });
};

export const createOffer = async (peerConnection, receiverId, createdID) => {
  const currentParticipantRef = participantRef.child(receiverId);
  peerConnection.onicecandidate = (event) => {
    event.candidate &&
      currentParticipantRef
        .child("offerCandidates")
        .pushMult({ ...event.candidate.toJSON(), userId: createdID });
  };

  const offerDescription = await peerConnection.createOffer();
  try{
    await peerConnection.setLocalDescription(offerDescription);
  }catch(error){
    console.log("gefangen setlocaldescription ", error);
  }

  const offer = {
    sdp: offerDescription.sdp,
    type: offerDescription.type,
    userId: createdID,
  };

  currentParticipantRef.child("offers").child().setMult({ offer });
};

export const initializeListensers = async (userId) => {
  const currentUserRef = participantRef.child(userId);
  currentUserRef.child("offers").onMessage(async (response) => {
    if(response.performed === "push" || response.performed === "set"){
      const data = response.value;
      if (data?.offer) {
        try{
          const pc = store.getState().participants[data.offer.userId].peerConnection;
          
            await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
            await createAnswer(data.offer.userId, userId);
          }catch(error){
            console.log("gefangen setremotedescribtion: ", error);
          }
        
      }
    }
  });

  currentUserRef.child("offerCandidates").onMessage(response => {
    if(response.performed === "push" || response.performed === "set"){
      const data = response.value;
      if (data.userId) {
        const pc = store.getState().participants[data.userId].peerConnection;
        if (pc.remoteDescription) {
          pc.addIceCandidate(new RTCIceCandidate(data));
        }
      }
    }
  });

  currentUserRef.child("answers").onMessage(response => {
    if(response.performed === "push" || response.performed === "set"){
      const data = response.value;
      if (data?.answer) {
        const pc =
          store.getState().participants[data.answer.userId].peerConnection;
        const answerDescription = new RTCSessionDescription(data.answer);
        if(pc.signalingState === "have-remote-offer" || pc.signalingState === "have-local-offer"){
          try{
            pc.setRemoteDescription(answerDescription);
          }catch(error){
            console.log("gefangen setremotedescribtion: ", error);
          }
        }
      }
    }
  });

  currentUserRef.child("answerCandidates").onMessage(response => {
    if(response.performed === "push" || response.performed === "set"){
      
      const data = response.value;
      if (data.userId) {
        const pc = store.getState().participants[data.userId].peerConnection;
          if (pc.remoteDescription) {
            pc.addIceCandidate(new RTCIceCandidate(data));
          }
      }
    }
  });
};

const createAnswer = async (otherUserId, userId) => {
  const pc = store.getState().participants[otherUserId].peerConnection;
  const participantRef1 = participantRef.child(otherUserId);
  pc.onicecandidate = (event) => {
    event.candidate &&
      participantRef1
        .child("answerCandidates")
        .pushMult({ ...event.candidate.toJSON(), userId: userId });
  };

  const answerDescription = await pc.createAnswer();
  if(pc.signalingState === "have-remote-offer" || pc.signalingState === "have-local-offer"){
    await pc.setLocalDescription(answerDescription);
  }

  const answer = {
    type: answerDescription.type,
    sdp: answerDescription.sdp,
    userId: userId,
  };

  participantRef1.child("answers").child().setMult({ answer });
};




//////////////////////////////////// Connect to Python RTC Server //////////////////////////////////////////////////////
export let connectionToPythonServerIsGiven = false;
export let connectToPythonServerPeerConnection = new RTCPeerConnection(iceServersForPyServer);
export let pythonDatachannel = connectToPythonServerPeerConnection.createDataChannel("state");
/*
let reconnectCounter = 0;
async function reconnectToPythonServer(){
  if(!connectionToPythonServerIsGiven && reconnectCounter <= 5){
    connectToPythonServerPeerConnection = new RTCPeerConnection(iceServersForPyServer);
    pythonDatachannel = connectToPythonServerPeerConnection.createDataChannel("state");
    let userstream = await getUserStream();
    connectToPythonServer(userstream);
    reconnectCounter = reconnectCounter+1
  }
}
*/

connectToPythonServerPeerConnection.addEventListener("connectionstatechange", (event) => {
    switch (connectToPythonServerPeerConnection.connectionState) {
      case "new":
        console.log("New");
        break;
      case "connecting":
        console.log("Connecting…");
        break;
      case "connected":
        console.log("Online");
        break;
      case "disconnected":
        console.log("Disconnecting…");
        break;
      case "closed":
        console.log("Offline");
        //reconnectToPythonServer();
        break;
      case "failed":
        console.log("Error");
        //reconnectToPythonServer();
        break;
      default:
        console.log("Unknown");
        //reconnectToPythonServer();
        break;
    }
  },
  false,
);

pythonDatachannel.addEventListener('close', () => {
  console.log("Disconnected");
  connectionToPythonServerIsGiven = false;
});
pythonDatachannel.addEventListener('open', () => {
  console.log("Connected");
  connectionToPythonServerIsGiven = true;
  if(connectionToPythonServerIsGiven){
    pythonDatachannel.send(JSON.stringify({...getState}))
  }
});
pythonDatachannel.addEventListener('message', (evt) => {
  console.log("Message get ", evt.data);
});

export const connectToPythonServer = async(stream) =>{
  //const peerConnection = new RTCPeerConnection(iceServersForPyServer);
  let tracksCount = 0;
  try{
    //um resourcen zu sparen kann man hier auf Audio only stellen oder es weglassen
    if(false){
      stream.getTracks().forEach((track) => {
        connectToPythonServerPeerConnection.addTrack(track, stream);
        tracksCount = tracksCount+1;
      });
    }else{
      //Es werden nur die Audio Streams übertragen!
      stream.getAudioTracks().forEach((track) => {
        if(track.id !== getSilentStreamId()){
          if(tracksCount !== 1){
            connectToPythonServerPeerConnection.addTrack(track, stream);
            tracksCount = tracksCount+1;}
        }
      });
    }
  }catch (error) {
    console.error(error);
  }
  const offerDescription = await connectToPythonServerPeerConnection.createOffer();
  await connectToPythonServerPeerConnection.setLocalDescription(offerDescription);
 

  let userDataJson = null;
  if(getUserInfoJson_filled()){
    userDataJson= getUserInfoJson_filled();
  } else{
    userDataJson = await getUserInfo();
  }
  //console.log("Nutzerdataen ", userDataJson);
  
  //python Server URL
  const offerURL = window.location.protocol + '//' + window.location.hostname + ':8060/offer';
  //send offer to python server
  fetch(offerURL, {
    body: JSON.stringify({
        sdp: offerDescription.sdp,
        type: offerDescription.type,
        roomID: db.roomId,
        userID: userIdForDB,
        userName: userName,
        userDataJson: userDataJson,
        tracksCount: tracksCount,
        video_transform: ""//document.getElementById('video-transform').value
    }),
    headers: {
        'Content-Type': 'application/json'
    },
    mode: "cors",
    referrerPolicy: "unsafe-url",
    method: 'POST'
  }).then(function(response) {
    //console.log("RESPONSE: "+response);
    return response.json();
  }).then(function(answer) {
    //console.log("ANSWER: "+answer);
    return connectToPythonServerPeerConnection.setRemoteDescription(answer);
  }).catch(function(e) {
    alert(e);
  });

  /*
  setInterval(function() {
    if(getState.video && !getState.screen){
      let image = makeVideoImageSnap("currentUserVideoElement");
      console.log(image)
      if(image && connectionToPythonServerIsGiven){
        //pythonDatachannel.send(image);
      }
    }
  }, 3000);
  */
  
  //connectToPythonServerPeerConnection = peerConnection;
  /*
  }).catch((error) => {
    console.log(error)
  });*/
};