import {
  SET_MAIN_STREAM,
  ADD_PARTICIPANT,
  SET_USER,
  REMOVE_PARTICIPANT,
  UPDATE_USER,
  UPDATE_PARTICIPANT,
} from "./actiontypes";

export const setMainStream = (stream) => {
  return {
    type: SET_MAIN_STREAM,
    payload: {
      mainStream: stream,
    },
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const addParticipant = (user) => {
  return {
    type: ADD_PARTICIPANT,
    payload: {
      newUser: user,
    },
  };
};

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const updateParticipant = (user) => {
  return {
    type: UPDATE_PARTICIPANT,
    payload: {
      newUser: user,
    },
  };
};

export const removeParticipant = (userId) => {
  return {
    type: REMOVE_PARTICIPANT,
    payload: {
      id: userId,
    },
  };
};


let silentSteamId = null;
export const setSilentStreamId = (value) => {
  silentSteamId = value;
};

export const getSilentStreamId = () => {
  return silentSteamId;
};

export const createSilenceAudioTrack = () => {
  let ctx = new AudioContext(), oscillator = ctx.createOscillator();
  let dst = oscillator.connect(ctx.createMediaStreamDestination());
  oscillator.start();
  setSilentStreamId(dst.stream.getAudioTracks()[0].id)
  return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
}

export const createEmptyVideoTrack = ({ width, height }) => {
  const canvas = Object.assign(document.createElement('canvas'), { width, height });
  canvas.getContext('2d').fillRect(0, 0, width, height);

  const stream = canvas.captureStream();
  const track = stream.getVideoTracks()[0];

  return Object.assign(track, { enabled: false });
};

// eslint-disable-next-line
class CustomError extends Error {
  constructor(name, message) {
    super(message);
    this.name = name;
  }
}

let videoPermission_requested = null;
let audioPermission_requested = null;
export const getUserStream = async () => {
  try{
    //to test the catch cases
    //throw new CustomError("NotReadableError", "Custom Error Message");
    const localStream = await navigator.mediaDevices.getUserMedia(
    {
      audio: { echoCancellation: true, noiseSuppression: true , volume: 1.0, sampleSize: 16, autoGainControl: true, contentHint:"",},
      video: true
    }
    );
    if(localStream){
      videoPermission_requested = "granted";
      audioPermission_requested = "granted";
    }
    localStream.addTrack(createSilenceAudioTrack());
    return localStream;
  }catch (error) {
    videoPermission_requested = "denied";
    audioPermission_requested = "denied";

    const getMicStream = async () => {
      try{
        const localStream = await navigator.mediaDevices.getUserMedia(
        {
          audio: { echoCancellation: true, noiseSuppression: true , volume: 1.0, sampleSize: 16, autoGainControl: true, contentHint:"",},
          video: false
        }
        );
        if(localStream){
          audioPermission_requested = "granted";
        }
        localStream.addTrack(createEmptyVideoTrack({ width:640, height:480 }));
        localStream.addTrack(createSilenceAudioTrack());
        return localStream;
      }catch(audio_issue){
        console.error(audio_issue);
        if (!(navigator.userAgent.indexOf("Firefox") !== -1)) {
          alert("Fehler: \nSie können den Dienst leider nur mit einer Zustimmung zu Audiozugriff verwenden.");
        }
      }
    }
    console.error(error);
    var localStream = new MediaStream();
    if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
      // User denied the request for audio and video access
      console.log("User denied access to audio and video");
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
        // The browser is Firefox
        alert("Video- oder Audiozugriff wurde abgelehnt. Bitte stimmen Sie diesem zu und laden Sie die Webseite erneut.");
      }else{
        alert("Video- oder Audiozugriff wurde abgelehnt. \nEs wird zumindest eine Audioberechtigung benötigt. Bitte stimmen Sie dieser zu.");
      }
      try{
        localStream = await getMicStream();
        //console.log("get Mic Stream: ", localStream);
        return localStream;
      }catch(e){
        alert("Fehler: ", e);
      }
    } else if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
      // User doesn't have the necessary devices
      console.log("Required audio or video devices not found");
      try{
        localStream = await getMicStream();
        //console.log("get Mic Stream: ", localStream);
        return localStream;
      }catch(e){
        alert("Fehler: ", e);
      }
    } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
      // User's devices are in use or unavailable
      console.log("Audio or video devices are in use or unavailable");
      try{
        localStream = await getMicStream();
        return localStream;
      }catch(e){
        alert("Fehler: ", e);
      }
    } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
      // User's devices don't meet the specified constraints
      console.log("Specified audio or video constraints not satisfied");
      try{
        localStream = await getMicStream();
        return localStream;
      }catch(e){
        alert("Fehler: ", e);
      }
    } else {
      // Other errors
      console.log("Error accessing audio and video:", error);
      alert("Es ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal.");
    }
  }
};
let userInfoJson_filled = null;
export const getUserInfoJson_filled = () => {return userInfoJson_filled;};
export const setUserInfoJson_filled = async () => {
    userInfoJson_filled = await getUserInfo();
};


export const getUserInfo = async () => {
  let language = navigator.language;
  let languageList = navigator.languages;
  let os = navigator.appVersion;
  let referrer = document.referrer;
  let timestamp = new Date();
  let screenResolution = `${window.screen.width}x${window.screen.height}`;
  let cookiesBool = navigator.cookieEnabled;
  let userAgent = navigator.userAgent;
  let deviceType = /mobile/i.test(userAgent) ? "Mobile" : "Desktop";
  let downloadSpeed = null;
  let connectionSpeed = null;
  let throttledConnection = null;
  let connectionType = null;
  if(window.navigator.connection){
    let connection_var = window.navigator.connection
    downloadSpeed = connection_var.downlink;
    connectionSpeed = connection_var.rtt;
    throttledConnection = connection_var.saveData;
    connectionType = connection_var.effectiveType;
  }
  let touchPoints = navigator.maxTouchPoints;
  let automated = navigator.webdriver;
  let ram = null;
  if(navigator.deviceMemory){
    ram = navigator.deviceMemory;
  }
  let cpu = navigator.hardwareConcurrency;

  const getPermissionStatus = async(name) => {
    try {
      if (navigator.permissions && typeof navigator.permissions.query === 'function') {
        let permission = await navigator.permissions.query({name});
        return permission.state;
      }else {
        // If the browser doesn't support navigator.permissions.query()
        if(name === "camera"){
          return videoPermission_requested;
        }
        if(name === "microphone"){
          return audioPermission_requested;
        }
        return null; //PermissionStatus.UNDETERMINED
      }
    }catch (err) {
      if(name === "camera"){
        return videoPermission_requested;
      }
      if(name === "microphone"){
        return audioPermission_requested;
      }
      return null; //PermissionStatus.UNDETERMINED
    }
  };
  let cameraPermission = await getPermissionStatus("camera");
  let microphonePermission =  await getPermissionStatus("microphone");
  let screencapturePermission = await getPermissionStatus("display-capture");
  
  const userDataJson= {
    language, languageList, os, referrer, timestamp, screenResolution, cookiesBool, userAgent, deviceType,
    downloadSpeed, connectionSpeed, throttledConnection, connectionType, touchPoints, automated, ram, cpu,
    cameraPermission, microphonePermission, screencapturePermission
  };

  return userDataJson;
};