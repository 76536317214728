import React from "react";
import Card from "../../Shared/Card/Card.component";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Participant.css";

export const Participant = (props) => {
  const {
    curentIndex,
    currentParticipant,
    hideVideo,
    videoRef,
    showAvatar,
    currentUser,
  } = props;
  
  if (!currentParticipant) return <></>;
  return (
    <div className={`participant ${hideVideo ? "hide" : ""}`}>
      <Card>
        <video
        ref={videoRef}
        style={{objectFit: showAvatar&&currentUser ? "none" : "cover", backgroundColor: showAvatar&&currentUser ? "#000000" : "#353b48"}}
        className={currentUser? "video currentUserVideoElement":"video"} //"video"
        id={`participantVideo${curentIndex}`} //{currentUser? "currentUserVideoElement":`participantVideo${curentIndex}`}
        autoPlay
        playsInline
        ></video>
        {!currentParticipant.audio && (
          <FontAwesomeIcon
            className="muted"
            icon={faMicrophoneSlash}
            title="Muted"
          />
        )}
        {showAvatar && (
          <div
            style={{ background: currentParticipant.avatarColor }}
            className="avatar"
          >
            {currentParticipant.name[0]}
          </div>
        )}
        <div className="name">
          {currentParticipant.name}
          {currentUser ? <small><b style={{color: "#ffd700"}}> (du)</b></small>: ""}
        </div>
      </Card>
    </div>
  );
};
