import WebSocketAsPromised from 'websocket-as-promised'; //https://github.com/vitalets/websocket-as-promised
import EventEmitter from 'events';

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

export const pyDB = class{
    eventEmitter = new EventEmitter();
    webSocket = new WebSocketAsPromised();
    clientId = "";
    roomId = "";

    constructor(wsUrl){
        this.webSocket = new WebSocketAsPromised(wsUrl, {
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data),
            attachRequestId: (data, requestId) => Object.assign({id: requestId}, data), // attach requestId to message as `id` field
            extractRequestId: data => data && data.id,                                  // read requestId from message `id` field
        });
    }

    initialize(wsUrl){
        this.webSocket = new WebSocketAsPromised(wsUrl, {
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data),
            attachRequestId: (data, requestId) => Object.assign({id: requestId}, data), // attach requestId to message as `id` field
            extractRequestId: data => data && data.id,                                  // read requestId from message `id` field
          });
    }

    async open(thenCall){
        await this.webSocket.open()
        .then(
            () => this.webSocket.onMessage.addListener(data => {
                data = JSON.parse(data);
                if (data.responseID === "connected") {
                    this.clientId = data.clientID;
                    this.roomId = data.roomID;
                    this.webSocket.removeAllListeners();
                    if(thenCall){
                        try{
                            thenCall();
                        } catch(error){
                            console.log("Wurde gefangen ", error);
                        }
                    }
                    this.eventEmitter.emit('pyDB-connect', null); 
                }
            })
        ).catch(e => {console.error(e);});
    }

    async close(){
        await this.webSocket.close();
    }

    sendData(data){
        if(this.webSocket.isOpened){
            this.webSocket.send(data);
        }
    }

    onConnected(work){
        this.eventEmitter.on('pyDB-connect', function () {
            if(work){
                try{
                    work();
                } catch(error){
                    console.log("Wurde gefangen ", error);
                }
            }
        });
    }

    async sendDataWithResponse(data, doWithData){
        if(this.webSocket.isOpened){
            this.webSocket.sendRequest(data) //json
            .then(response => {
                if(doWithData){
                    try{
                        doWithData(response);
                    } catch(error){
                        console.log("Wurde gefangen ", error);
                    }
                }
            }
            ).catch(e => console.error(e));
        }
    }
    
    addListenerWithAction(action){
        this.webSocket.onMessage.addListener(
            data => {
                action(data);
            }
            );
    }    

}

export const dbRef = class{
    path = [];
    db;
    key = "";
    constructor(database, path){
        this.path = path;
        this.db = database;
        if(path.length > 0){
            this.key = path[path.length - 1];
        } else { this.key = database.roomId; }
    }
    setPath(path){
        this.path = path;
    }
    child(childName){
        var copyPath = [...this.path];
        if(!childName){childName=generateUUID();}
        copyPath.push(childName);
        var ref = new dbRef(this.db, copyPath)
        return ref;
    }
    parent(){
        var copyPath = [...this.path];
        if (copyPath.length > 0){
            copyPath.pop();
        }
        var ref = new dbRef(this.db, copyPath)
        return ref;
    }
    push(json){
        var newID = generateUUID();
        var newPath = [...this.path];
        newPath.push(newID);
        if(json){
            json["generatedUUID"] = newID
            var data = {
                "push":{"json":json, "path":this.path.join(', ')},
            }
            this.db.sendDataWithResponse(data);
        }
        return new dbRef(this.db, newPath);
    }
    pushMult(json){
        var newID = generateUUID();
        var newPath = [...this.path];
        newPath.push(newID);
        json["generatedUUID"] = newID
        var data = {
            "push":{"json":json, "path":this.path.join(', ')},
            "multipleAnswer":true,
        }
        this.db.sendDataWithResponse(data);
        return new dbRef(this.db, newPath);
    }
    set(json){
        var data = {
            "set":{"json":json, "path":this.path.join(', ')}
        }
        this.db.sendDataWithResponse(data);
    }
    setMult(json){
        var data = {
            "set":{"json":json, "path":this.path.join(', ')},
            "multipleAnswer":true,
        }
        this.db.sendDataWithResponse(data);
    }
    update(json){
        var data = {
            "update":{"json":json, "path":this.path.join(', ')}
        }
        this.db.sendDataWithResponse(data);
    }
    delete(){
        var data = {
            "delete":{"path":this.path.join(', ')}
        }
        this.db.sendDataWithResponse(data);
    }
    snap(doWithData){
        var data = {
            "snap":{"path":this.path.join(', ')}
        }
        this.db.sendDataWithResponse(data, doWithData);
    }
    onMessage(doTask){
        this.db.webSocket.onMessage.addListener(data => {
            data = JSON.parse(data);
            if(data.broadcast && data.broadcast.path){
                data.broadcast.path.shift();
                if (data.broadcast.path.join(',') === this.path.join(',')){
                    try{
                        doTask(data.broadcast);
                    } catch(error){
                        console.log("Fehler wurde gefangen ", error);
                    }
                }
            }
        });
    }
    onDisconnect(){
        var dcObj = new disconnectObject(this.db, this.path);
        return dcObj;
    }
}

const disconnectObject = class{
    path = [];
    db = new pyDB();
    constructor(database, path){
        this.path = path;
        this.db = database;
    }
    push(json){
        var data = {"onDisconnect":{
            "push":{"json":json, "path":this.path.join(', ')}
        }}
        this.db.sendDataWithResponse(data);
    }
    set(json){
        var data = {"onDisconnect":{
            "set":{"json":json, "path":this.path.join(', ')}
        }}
        this.db.sendDataWithResponse(data);
    }
    update(json){
        var data = {"onDisconnect":{
            "update":{"json":json, "path":this.path.join(', ')}
        }}
        this.db.sendDataWithResponse(data);
    }
    delete(){
        var data = {"onDisconnect":{
            "delete":{"path":this.path.join(', ')}
        }}
        this.db.sendDataWithResponse(data);
    }
}