import React, { useRef, useEffect } from "react";
import MeetingFooter, {screenSharedUpdated} from "../MeetingFooter/MeetingFooter.component";
import Participants from "../Participants/Participants.component";
import "./MainScreen.css";
import { connect } from "react-redux";
import { setMainStream, updateUser, getSilentStreamId, createSilenceAudioTrack, getUserStream } from "../../store/actioncreator";
import { connectToPythonServerPeerConnection } from "../../server/peerConnection";
import CookieConsent from "react-cookie-consent"; //https://www.npmjs.com/package/react-cookie-consent

const MainScreen = (props) => {
  const participantRef = useRef(props.participants);

  const onMicClick = (micEnabled) => {
    if (props.stream) {
      const streamlist = props.stream.getAudioTracks();
      //console.log(streamlist);
      for (let stream = 0;  stream < streamlist.length; stream++) {
        if(streamlist[stream].id !== getSilentStreamId()){
          streamlist[stream].enabled = micEnabled;
        }
      }
      /*
      if(streamlist[0]){//props.stream.getAudioTracks()[0]){
        //props.stream.getAudioTracks()[0].enabled = micEnabled;
        streamlist[0].enabled = micEnabled;
        if(streamlist[1] && (streamlist[1].contentHint !== "speech-recognition" && props.stream.getAudioTracks()[1].label !== "MediaStreamAudioDestinationNode")){ // props.stream.getAudioTracks()[1] && props.stream.getAudioTracks()[1].label !== "MediaStreamAudioDestinationNode"
          //props.stream.getAudioTracks()[1].enabled = micEnabled;
          streamlist[1].enabled = micEnabled;
        }
      }
      */
      props.updateUser({ audio: micEnabled });
    }
  };
  const onVideoClick = (videoEnabled) => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = videoEnabled;
      props.updateUser({ video: videoEnabled });
    }
  };

  useEffect(() => {
    participantRef.current = props.participants;
  }, [props.participants]);

  const updateStream = (stream) => {
    for (let key in participantRef.current) {
      const sender = participantRef.current[key];
      if (sender.currentUser) {
        if(connectToPythonServerPeerConnection.iceConnectionState === "connected"){
          trackReplacerForPeerConnection(connectToPythonServerPeerConnection);
        }
        
        //continue;
      }else{
        trackReplacerForPeerConnection(sender.peerConnection);
      }

      function trackReplacerForPeerConnection(peerConnectionUsed){
        if(!sender.currentUser){
          const peerConnection = peerConnectionUsed
            .getSenders()
            .find((s) => (s.track ? s.track.kind === "video" : false));
          peerConnection.replaceTrack(stream.getVideoTracks()[0]);
        }
        if(stream.getAudioTracks()[0]){
          function isKindOfTrackAudio(rctSender){
            //console.log("track-- " + rctSender.track.kind);
            return rctSender.track.kind === "audio";
          }
          const peerConnection = peerConnectionUsed
          .getSenders()
          .filter(isKindOfTrackAudio);

          //.find((s) => (s.track ? s.track.kind === "audio" : false));
          //console.log("current peer value with audio" + sender.peerConnection.getAudioTracks());
          /*
          let silence = () => {
            let ctx = new AudioContext(), oscillator = ctx.createOscillator();
            let dst = oscillator.connect(ctx.createMediaStreamDestination());
            oscillator.start();
            setSilentStreamId(dst.stream.getAudioTracks()[0].id);
            return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false, });
          }*/
          for(let item in peerConnection){
            peerConnection[item].replaceTrack(stream.getAudioTracks()[item]!==undefined?stream.getAudioTracks()[item]:createSilenceAudioTrack());
          }
          //peerConnection.replaceTrack(stream.getAudioTracks()[1]!== undefined? stream.getAudioTracks()[1] : stream.getAudioTracks()[0]);
        }
      }
      
    }
    props.setMainStream(stream);
  };

  const onScreenShareEnd = async () => {
    try{
      const localStream = await getUserStream();
      /*
      const localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      */

      localStream.getVideoTracks()[0].enabled = Object.values(
        props.currentUser
      )[0].video;

      for (var audiostreams in localStream.getAudioTracks()){
        if(localStream.getAudioTracks()[audiostreams].id !== getSilentStreamId()){
          localStream.getAudioTracks()[audiostreams].enabled = Object.values(
            props.currentUser
          )[0].audio;
        }
      }
      /*
      localStream.getAudioTracks()[0].enabled = Object.values(
        props.currentUser
      )[0].audio;
      */
      /*
      let silence = () => {
        let ctx = new AudioContext(), oscillator = ctx.createOscillator();
        let dst = oscillator.connect(ctx.createMediaStreamDestination());
        oscillator.start();
        setSilentStreamId(dst.stream.getAudioTracks()[0].id);
        return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
      }
      */
      //localStream.addTrack(createSilenceAudioTrack());

      updateStream(localStream);

      props.updateUser({ screen: false });
      screenSharedUpdated(false);
    }catch(error){
      console.log(error);
    }
  };

  const onScreenClick = async () => {
    var screenActive = false;
    for (var i in props.currentUser){
      screenActive = props.currentUser[i]["screen"];
    }
    if(screenActive){
      for (var stream in props.stream.getTracks()){
        props.stream.getTracks()[stream].stop();
        //onScreenShareEnd();
      }
      onScreenShareEnd();
      return;
    }
    
    try{
      let neededStream = props.stream.getAudioTracks()[0];
      for(const audioStreams in props.stream.getAudioTracks()){
        if(props.stream.getAudioTracks()[audioStreams].id !== getSilentStreamId()){
          neededStream = props.stream.getAudioTracks()[audioStreams]
        }
      }
      const myOldAudio = neededStream;
      let mediaStream;
      if (navigator.getDisplayMedia) {
        mediaStream = await navigator.getDisplayMedia({ video: true, audio: true });
        console.log("1: mediaStream = await navigator.getDisplayMedia({ video: true, audio: true });");
      } else if (navigator.mediaDevices.getDisplayMedia) {
        mediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true
        });
        console.log("2: mediaStream = await navigator.mediaDevices.getDisplayMedia({");
      } else {
        mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { mediaSource: "screen" },
          audio: true
        });
        console.log("3: mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: { mediaSource:},");
      }
      
      mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;
      const newTrack = mediaStream.getAudioTracks()[0];
      mediaStream.removeTrack(newTrack);
      mediaStream.addTrack(myOldAudio);
      mediaStream.addTrack(newTrack)
      mediaStream.getAudioTracks()[0].enabled = myOldAudio.enabled;
      mediaStream.getAudioTracks()[1].enabled = myOldAudio.enabled;
      
      updateStream(mediaStream);

      props.updateUser({ screen: true });
      screenSharedUpdated(true);
    } catch (error){
        console.error(error);
    }
  };
  return (
    <div className="wrapper">
      <div className="main-screen">
        <Participants />
      </div>

      <div className="footer">
        <MeetingFooter
          onScreenClick={onScreenClick}
          onMicClick={onMicClick}
          onVideoClick={onVideoClick}
        />
      </div>
      <div className="cookie_police">
        <CookieConsent 
        location="bottom" 
        cookieName="coockieaccepted" 
        expires={999}
        buttonText="Akzeptieren"
        style={{
          textAlign:"center",
        }}
        buttonStyle={{
        }}
        overlay>
          🍪 Diese Webseite nutzt einige Funktionen, die ein setzen von Cookies notwendig macht. <br></br>
          Bitte akzeptieren Sie die Nutzung von Cookies um den Dienst nutzen zu können.
        </CookieConsent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    participants: state.participants,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);